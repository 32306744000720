import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0056 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="「ワークライフバランス」とは？意味やメリットなどを徹底解説！" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.08.10</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        働き方改革を契機に注目されるキーワードの1つに挙げられているのが「ワークライフバランス」です。現在、我が国は多様な働き方が尊重される時代に突入しており、「仕事一辺倒」がよしとされる文化は終焉を迎えようとしています。今回はワークライフバランスについて、定義や必要性、メリット等について解説します。{' '}
      </p>
      <img src="/images/column/details/c0056.jpg" alt="キンクラコラム画像56" />
      <h1 id="toc1">ワークライフとは？</h1>
      <p>
        まず、「ワークライフ」とは<span>「仕事と生活の調和」</span>
        と定義されており、かつ、2つの概念があります。1つ目は仕事と生活においてそれぞれバランスがとれている状態のことを指し、2つ目は性差別を受けず、能力に応じた均等な機会を与えることです。
      </p>

      <h1 id="toc2">なぜ必要なのか？</h1>
      <p>
        ワークライフバランスへの取り組み、そして実現することでいくつかのメリットと課題があります。まずはメリットについてですが、労働者の内発的動機の向上が見込めます。共働きや核家族化が一般化している現代において、子育てや親の介護と並行しながら働くことは珍しくなくなってきました。
        <span>
          そこで、ワークライフバランスに積極的に取り組む企業であれば、離職を選択する必要性がなくなります。
        </span>
        繰り返しになりますが、仕事だけの状態が継続することで自分自身の心身に支障をきたすこともあります。また、生活だけを重視することでキャリアの上積みが困難となります。よって、双方をバランスよく積み重ねていくことで、社会貢献することができ、その結果、対価を受け、より良い生活を送ることが可能となります。次に性差別を受けず、能力に応じた均等な機会を与えることについて、社会的にもハラスメントへの目が厳しくなり、性差別が蔓延る組織では労働者の内発的動機が低下し、離職の誘因となることも珍しくなくなっています。労働力不足の現代において、繰り返し採用活動を進めるのは生産的とは言えず、
        <span>離職が起きにくい環境整備</span>は最低限必要なタスクです。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">課題と対応策について</h1>
      <p>
        次に、課題についてです。ワークライフバランスの代表的なものとして、
        <span>多様な働き方を認める</span>
        ことです。例えば労働者に始業および就業の時刻の決定をゆだねるフレックスタイム制の導入や、時差出勤、テレワーク制度などがあります。多様な働き方を導入することで固定労働時間制のみの場合と比較して、労務課管理が煩雑になることは想像に難くないでしょう。どこからが残業代となるのか、どのように労働時間管理をすべきか、働き方の選択肢が増えるにつれ、その分課題も挙げられます。
        <br />
        <br />
        実例を見ていきましょう。例えばテレワークであれば、対面業務と異なり、いつ仕事を開始していつ終わっているのか上司による確認ができません。また、些細な確認であっても近くのデスクで業務をしているわけではないので、何らかの手段を用いて連絡を取る必要があります。このような「手間」があるからワークライフバランスは取り組めないと考えてしまっては先に進むことはありません。そこで、
        <span>
          労働時間管理であればより客観的かつ、適宜確認が可能な、勤怠管理ツールを導入することでその負担は軽減します。
        </span>
        フレックスタイム制についても1日単位で残業代が決定するわけではありませんので、勤怠管理ツールを導入することで残業代の計算誤りを防ぐことが可能となります。このように多様な働き方については（もちろん初期設定にある程度の時間がかかる点は否めませんが）勤怠管理ツールを導入することで大部分は負担軽減が可能です。
        <br />
        <br />
        もう一つの課題として、必ず一部の反対勢力がでることです。新しい取り組みを導入することでこれまで過ごしてきた環境が侵害されると勘違いしていることが挙げられます。もちろん、テレワークなどについては研修すら終えていない入社間もない新卒社員にはハードルが高すぎるため、一定の勤続期間経過後というハードルを設けることもあるでしょう。しかし、合理的な理由であればこのようなハードルを設けることは問題ありませんし、一部の反対勢力は多くの場合、新卒社員よりもベテラン勢となることが一般的です。よって、説明会を開催するなど、会社として、
        <span>
          「なぜ必要なのか？」、「課題」、「将来的に目指すべき方向性」などを懇切丁寧に説明すること
        </span>
        で、納得感を醸成することが可能と考えます。
      </p>

      <h1 id="toc4">実際の取り組み内容</h1>
      <p>
        多様な休暇制度の導入です。もちろん有給休暇の残日数もあるのでしょうが、使用用途が限定された特別休暇であれば、有給休暇よりも申請しやすいという心理的なメリットがあります。有給休暇の場合、原則として、使用用途に限定はなく、取得理由を開示する法的義務もないことから他の人の目が気になると感じる労働者が多いとの指摘があります。具体例としては教育訓練休暇などがあり、資格試験前の追い込み期に申請するといった取得事例があります。この休暇制度を利用することで、短期的にはもちろん人手不足にはなるのでしょうが、長期的にはスキルアップ後の労務提供の受領が可能となりますので、メリットになると言えるでしょう。{' '}
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0056
